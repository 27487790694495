import React from "react";
import { Youtube } from "../../components/Video";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function Vernissage() {
  return (
    <>
      <div className="projectBlock">
        <p>
          <b>
            Participation in the national socially significant project
            "AMBASSADORS OF THE FUTURE FOR THE REGIONS" with the support of the
            "Right to life" social fund, Moscow, Russia
          </b>
        </p>
        <p>
          Mariia Plekhova has become a participant in the socially significant
          project called "Ambassadors of the Future" intended to motivate
          teenagers to adopt a healthy and active lifestyle.
        </p>
        <p>
          The project is implemented with the support of the social foundation
          "Right to Life" and the Presidential Grants Fund.
        </p>
      </div>
      <div className="projectBlock">
        <div>
          <Youtube
            width="560"
            height="315"
            src="https://www.youtube.com/embed/70fjMi6QAyw"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></Youtube>
        </div>
      </div>
    </>
  );
}

export default Vernissage;

import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(require.context("../../projects/2", false, /\.(png|jpe?g|svg)$/));

function OnTheWall() {
  return (
    <>
      <div className="projectBlock">
        <p>
          <span> Solo exhibition "A painting floats on the wall"</span>
        </p>
        <p>
          <span>
            Museum of the city of Nizhny Novgorod, Nizhny Novgorod, Russia; sept
            2018
          </span>
        </p>
        <p>
          The exhibition of paintings titled "A painting floats on the
          wall" is a solo exhibition by artist Maria Plekhova held at the
          Nizhny Novgorod Museum. While the artworks are not united by a common
          theme, each of them reflects the artist's desire to convey the
          elusiveness and uniqueness of the moment. Viewers also note the
          lightness, simplicity, and freshness of perception in her works.
        </p>
        <p>
          <span>
            Mariia Plekhova has previously participated in the All-Russian
            Exhibition "Art Russia" and exhibited her works at the "Orlenok*
            Cinema Theater. She expresses immense gratitude for the opportunity
            to hold the exhibition within the walls of the museum. "A
            painting floats on the wall"; is a significant event for her.
            Just few days after the opening, she will be leaving for Italy,
            where she will spend the next year of her life. This exhibition will
            serve as a good conclusion before taking a new step in her artistic
            journey.
          </span>
        </p>
        <p>
          <span>
            {" "}
            The title reflects Maria's belief that we are all in constant
            motion, in a continuous flow of life, energy, and information. To
            avoid getting lost and not "drown" in this stream, it is important
            to focus on what is essential. Maria has come to understand this
            while painting.
          </span>
        </p>
        <p>
          The exhibition "A painting floats on the wall" showcases
          the unique works of Maria Plekhova, which embody her vision of the
          world and her artistic philosophy.
        </p>
      </div>
      <div className="projectBlock">
        <Carousel autoPlay={true} infiniteLoop={true} showIndicators={false}>
          {images.map((im) => (
            <img src={im} alt="photo2" key={im}/>
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default OnTheWall;

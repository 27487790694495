import React from "react";
import { Youtube } from "../../components/Video";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(
  require.context("../../projects/4", false, /\.(png|jpe?g|svg|JPG)$/)
);

function ChoiceExists() {
  return (
    <>
      <div className="projectBlock">
        <p>
          <b>
            Solo exhibition "Choice Exists" by Mariia Plekhova and Oleg Kumagin,
            realized in collaboration with the All-Russian Scientific Research
            Institute of Phytopathology. Cinema "Орленок" Nizhny Novgorod,
            Russia; oct 2021
          </b>
        </p>
        <p>
          Artists Oleg Kumagin and Mariia Plekhova presented a series of works
          dedicated to phytopathology, the science of plant diseases.
        </p>
        <p>
          The exhibition "Choice Exists!" is the result of a new socially
          significant direction in art. It is a symbiosis of relevant scientific
          knowledge and memorable artistic images. The contrast in the works
          helps to emphasize the importance of conscious choices in food
          selection.
        </p>
        <p>
          The artists introduce visitors to the All-Russian Scientific Research
          Institute of Phytopathology, which is engaged in preventing plant
          diseases, increasing crop yield and quality. The health of plants, in
          turn, ensures the health of the entire nation.
        </p>
        <p>
          The exhibition calls for reflection on the quality of food products
          that are included in our daily diet. The artists advocate for
          conscious choices in food and convey the idea to a wide audience
          through contrasting artworks
        </p>
      </div>
      <div className="projectBlock">
        <div>
          <Youtube
            width="560"
            height="315"
            src="https://www.youtube.com/embed/xcqLfVrQ4Xs"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></Youtube>
        </div>
        <div>
          <Youtube
            width="560"
            height="315"
            src="https://www.youtube.com/embed/3TnjL1zev9A"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></Youtube>
        </div>
        <Carousel autoPlay={true} infiniteLoop={true} showIndicators={false}>
          {images.map((im) => (
            <img src={im} alt="photo2" key={im} />
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default ChoiceExists;

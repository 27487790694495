import Img1 from './2021/2021_1.png'
import Img2 from './2021/2021_2.png'
import Img3 from './2021/2021_3.png'
import Img4 from './2021/2021_4.png'
import Img5 from './2021/2021_5.png'
import Img6 from './2021/2021_6.png'

import Img1s from './2021s/2021_1.png'
import Img2s from './2021s/2021_2.png'
import Img3s from './2021s/2021_3.png'
import Img4s from './2021s/2021_4.png'
import Img5s from './2021s/2021_5.png'
import Img6s from './2021s/2021_6.png'

export const images2021 = [
    {
        src: Img1,
        src_s: Img1s,
        id: 22,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img2,
        src_s: Img2s,
        id: 23,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img3,
        src_s: Img3s,
        id: 24,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img4,
        src_s: Img4s,
        id: 25,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img5,
        src_s: Img5s,
        id: 26,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img6,
        src_s: Img6s,
        id: 27,
        caption: "foo",
        thumbnailCaption: "bar",
    },
]

import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(
  require.context("../../projects/21", false, /\.(png|jpe?g|svg|JPG)$/)
);

function Artweek() {
  return (
    <>
      <div className="projectBlock">
        <p>
          <b>
            Nicolaquinto Italia and the artist Mariia Plekhova present project
            FILO as part of ROME ART WEEK.
          </b>
        </p>
        <b>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://romeartweek.com/en/artists/?code=ADWAXI"
          >
            ROME ART WEEK MARIIA PLEKHOVA
          </a>
        </b>
        <p>
          Rome Art Week is an annual city-wide event in Rome dedicated to
          contemporary art, offering a variety of exhibitions, open studios, and
          cultural projects, connecting artists, curators, and the public while
          showcasing new perspectives in the contemporary art scene.
        </p>
        <p>
          The Nicolaquinto showroom in the center of Rome will present a
          collection of sofas in symbiosis with the paintings of the artist
          Mariia Plekhova. In addition, each work has a continuation in virtual
          reality. Thanks to the technology of virtual reality glasses, guests
          will have the opportunity to immerse themselves inside each painting.
        </p>
        <p>
          The technique of symbiosis between a painting and a sofa is aimed at
          making art a part of a person's everyday life. Constant interaction
          with objects made with this technique allows a person to experience
          art on a different level, making it an integral part of their reality.
          Therefore, life, surrounded by such works, becomes a work of art
          itself.
        </p>
        <p>
          The goal of FILO is to democratize art. The activity is aimed at
          creating an accessible environment for interaction with art. This
          becomes possible thanks to the technique of combining a traditional
          artistic canvas with an everyday object - a sofa, through mechanical
          interaction and mutual exchange of parts. Thanks to the FILO
          technique, an everyday object transforms its function and meaning and
          becomes an element of art.
        </p>
        <p>
          As part of the Rome Art Week project, in addition to the art
          exhibition, the Nicolaquinto showroom will host free creative
          workshops for children and adults, organized by the artist Mariia
          Plekhova; an open studio, where there will be an opportunity to
          observe the artist's work; artist talk and discussion open to all.
        </p>
      </div>
      <div className="projectBlock">
        <Carousel autoPlay={true} infiniteLoop={true} showIndicators={false}>
          {images.map((im) => (
            <img src={im} alt="photo2" key={im} />
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default Artweek;

import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(
  require.context("../../projects/24", false, /\.(png|jpe?g|svg|JPG)$/)
);

function EdgeOfReality() {
  return (
    <>
      <div className="projectBlock">
        <p>
          <b>
            Participation in Maker Faire Rome – The European Edition 2024:
            Italia Media Art Festival with Virtual Reality Painting workshop "At
            the Edge of Reality"
          </b>
        </p>
        <p>
          Mariia Plekhova participated in Maker Faire Rome – The European
          Edition 2024 as part of the Italia Media Art Festival, presenting her
          innovative approach to art and technology. She conducted the Virtual
          Reality Painting Workshop "At the Edge of Reality", where participants
          explored creative expression using cutting-edge VR tools, immersing
          themselves in a digital artistic environment. The workshop offered a
          unique opportunity to blend art with technology, pushing the
          boundaries of imagination and innovation.
        </p>
        <p>
          Additionally, Mariia's short film, "Creatore," created entirely with
          the use of artificial intelligence, was also screened at Maker Faire.
          The film explores the parallels between human creativity and AI,
          celebrating the harmony of art, technology, and progress.
        </p>
        <p>
          Mariia’s dual contribution—a VR workshop and the presentation of her
          film—showcased her commitment to redefining artistic experiences
          through innovation and creativity.
        </p>
        <p>Press & Publications</p>
        <p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://theartlibido.com/italia-media-art-festival-a-maker-faire/?fbclid=PAZXh0bgNhZW0CMTEAAaZ1F0Fi6Bt_RgxP1d_NiDfJBQJu_nXPyIfwJtkNP6PMrUZiadCsjqQU0nQ_aem_kY6il3eWiqM6eTis0HkQ-g"
          >
            The Art Libido
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.arte.it/calendario-arte/roma/mostra-italia-media-art-festival-imaf-99433?fbclid=PAZXh0bgNhZW0CMTEAAabA58U4FOdvbPZOIjKjBuW0nyLP75hBLmMZYL1BCOZBrZ_wiEFhStDHVEE_aem_xWGEi_vIr4tpcWo-9bMrfQ"
          >
            ARTE.it
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://makerfairerome.eu/en/events-en/?edition=2024&event_id=2400154&doing_wp_cron=1729530401.1213970184326171875000"
          >
            Maker Faire Rome
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.italiamediaartfestival.it/notizie/"
          >
            Italia Media Art Festival
          </a>
        </p>
      </div>
      <div className="projectBlock">
        <Carousel autoPlay={true} infiniteLoop={true} showIndicators={false}>
          {images.map((im) => (
            <img src={im} alt="photo2" key={im} />
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default EdgeOfReality;

import React from "react";
import Navbar from "./components/Navbar";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import About from "./pages/about";
import Contact from "./pages/contact";
import Projects from "./pages/projects";
import ProjectPage from "./pages/project_page";
import Paintings from "./pages/paintings";
import Personal from "./pages/personal";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Navigate to="/paintings/2024" />} />
        <Route path="/paintings">
          <Route path="" element={<Navigate to="2024" />} />
          <Route path=":year" element={<Paintings />} />
        </Route>
        <Route path="/about" element={<About />} />
        <Route path="/projects">
          <Route path="" element={<Projects />} />
          <Route path=":project_name" element={<ProjectPage />} />
        </Route>
        <Route path="/contact" element={<Contact />} />
        <Route path="/personal">
          <Route path="" element={<Navigate to="/paintings/2024" />} />
          <Route path=":id" element={<Personal />} />
        </Route>
        <Route path="*" element={<Navigate to="/paintings/2024" />} />
      </Routes>
    </Router>
  );
}

export default App;

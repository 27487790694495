import React from 'react';
import {
    Nav,
    NavLink,
    Bars,
    NavMenu,
    NavHeader,
    Sticky,
    YearsMenu
} from './NavbarElements';
import Sidebar from './SideBar';
import { useMediaQuery } from "@uidotdev/usehooks";
import { useLocation } from 'react-router-dom';
import { useScrollDirection } from '../hooks'

const Navbar = () => {
    const [side, setSide] = React.useState(false);
    const isSmallDevice = useMediaQuery(`only screen and (max-width : 768px)`);
    const location = useLocation();
    const isPaintings = location.pathname.startsWith("/paint")
    const scrollDirection = useScrollDirection()
    return (
        <>
            {isSmallDevice && side && <div style={{ zIndex: "30" }}>
                <Sidebar closeBar={() => setSide(false)} />
            </div>}
            <Sticky scrolledDown={scrollDirection === "down" ? true : false} >
                <Nav>
                    <NavHeader to='/'>
                        MARIIA PLEKHOVA
                    </NavHeader>
                    <Bars onClick={() => setSide(true)} />
                    <NavMenu>
                        <NavLink to='/about' activeStyle>
                            ABOUT
                        </NavLink>
                        <NavLink to='/paintings' activeStyle>
                            PAINTINGS
                        </NavLink>
                        <NavLink to='/projects' activeStyle>
                            PROJECTS
                        </NavLink>
                        <NavLink to='/contact' activeStyle>
                            CONTACT
                        </NavLink>
                    </NavMenu>
                </Nav>
                {!side && isPaintings && <Nav>
                    <YearsMenu>
                        <NavLink to='/paintings/2024' activeStyle>
                            2024
                        </NavLink>
                        <NavLink to='/paintings/2023' activeStyle>
                            2023
                        </NavLink>
                        <NavLink to='/paintings/2022' activeStyle>
                            2022
                        </NavLink>
                        <NavLink to='/paintings/2021' activeStyle>
                            2021
                        </NavLink>
                        <NavLink to='/paintings/2020' activeStyle>
                            2020
                        </NavLink>
                        <NavLink to='/paintings/2019' activeStyle>
                            2019
                        </NavLink>
                        <NavLink to='/paintings/2018' activeStyle>
                            2018
                        </NavLink>
                        <NavLink to='/paintings/2017' activeStyle>
                            2017
                        </NavLink>
                    </YearsMenu>
                </Nav>}
            </Sticky >
        </>
    );
};

export default Navbar;

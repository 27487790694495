import React from "react";
import { Youtube } from "../../components/Video";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(
  require.context("../../projects/1", false, /\.(png|jpe?g|svg)$/)
);

function AstrazioniSonore() {
  return (
    <>
      <div className="projectBlock">
        <p>
          <span>
            Exhibition-performance &ldquo;ASTRAZIONI SONORE&rdquo; ("SOUND
            ABSTRACTIONS")&nbsp; by Mariia Plekhova and Mario Rapisardi.&nbsp;
          </span>
        </p>
        <p>
          <span>
            Palazzo delle Arti, ex Convitto Ragusa, Noto, Italy; gen 2019
          </span>
        </p>
        <p>
          <span>
            The opening of exhibition-performance&nbsp; "ASTRAZIONI
            SONORE"&nbsp; by Mariia Plekhova and Italian artist Mario Rapisardi
            took place in Noto, Sicily, Italy. The presented works are the
            result of layer upon layer, which occurred within the framework of
            the experiment. What was presented at the opening is not the final
            outcome; the changes will continue. Mario Rapisardi has been
            studying the Kn art movement for many years, which is based on the
            belief that paintings can be considered alive only when they, like
            humans, are in constant flux.
          </span>
        </p>
        <ul>
          <li>
            <span>Carlo Belli</span>
          </li>
        </ul>
        <p>
          <span>
            &laquo;Finalmente un quadro che non dice nulla - esclamai con un
            sospiro di sollievo&raquo;
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            Review of the exhibition &ldquo;ASTRAZIONI SONORE&rdquo;&nbsp; by
            art critic Michele Romano and professor Cristina Cataneo:
          </span>
        </p>
        <p>
          <span>
            Spatial chromaticism and cosmic visions in the works of Mario
            Rapisardi and Mariia Plekhova. The gestural synergy is undoubtedly
            the foundational principle of the pictorial/spatial experimentation
            of these two artists who engage in a dialogue in the chamber of
            transformations in Noto. It is a wunderkammer, a chamber of wonders
            and abstract chromaticism, which qualifies that genius loci and
            combines the master's experience with the generational search of the
            disciple/prot&eacute;g&eacute;.
          </span>
        </p>
        <p>
          <span>
            The works of pure chromatic abstraction invite the attentive
            observer to delve into the narrative introspection of matter as a
            microcosm of an alternative reality, the poetic vision of timeless
            art that poetically leads from gesture to the universality of being,
            a vision that is not purely visual but primarily sensory, an
            ancestral and conceptual conception of the contemporary where matter
            transmutes into the mirror of the soul.
          </span>
        </p>
        <p>
          <span>
            When art "is a source of the Sublime... it is the strongest emotion
            that the soul is capable of feeling," it is the romantic conceptions
            of Edmond Burke (1759) that prove to be contemporary to the dialogue
            and harmony of our two artists, a synesthetic synergy of the arts,
            where the place generates universal images and sounds. It is a pure
            philosophical theory of the arts that Goethe revitalizes in the
            principles of Plotinus, where "no eye has ever seen the sun without
            becoming like the sun, nor can a soul see beauty without becoming
            beautiful." It is in his "Theory of Colors" (1810) that the German
            philosopher defines, "If the eye were not solar, how could we see
            the light?"
          </span>
        </p>
        <p>
          <span>
            In the extreme chromatic and gestural abstraction of
            Rapisardi/Plekhova's visual production, the principles of "liquid
            modernity" emerge, the contemporary theory of sociologist Zygmunt
            Bauman, which indicates that "change is the only permanent thing and
            uncertainty is the only certainty." Contemporary experimentation is
            therefore a careful ongoing research that leads the "liquid society"
            towards new frontiers of introspection of being and the creation of
            a sensory dialogue, from the chromatic gesture of Mario Rapisardi
            and Mariia Plekhova to the unified sound of Gero Cangemi and Antonio
            Agosta, teachers and students.
          </span>
        </p>
      </div>
      <div className="projectBlock">
        <div>
          <Youtube
            src="https://www.youtube.com/embed/qfFhxAc5tCw"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></Youtube>
        </div>
        <div>
          <Youtube
            src="https://www.youtube.com/embed/VB_ApR4qJi0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></Youtube>
        </div>
        <Carousel autoPlay={true} infiniteLoop={true} showIndicators={false}>
          {images.map((im) => (
            <img src={im} alt="photo2" key={im} />
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default AstrazioniSonore;

import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useMediaQuery } from "@uidotdev/usehooks";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  a {
    color: #808080;
  }
`;

const baseUrl = "https://api.mariiaplekhova.com";
// const baseUrl = "http://127.0.0.1:5000";

export default function Personal() {
  const [data, setData] = React.useState(null);
  const isSmallDevice = useMediaQuery(`only screen and (max-width : 768px)`);

  const props = useParams();
  const id = props.id;

  React.useEffect(() => {
    fetch(`${baseUrl}/art/${id}/check`, {})
      .then((resp) => {
        if (resp.status < 400) {
          return resp.json();
        }
        throw new Error("Something went wrong");
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setData(null);
      });
  }, [id]);

  let video_url = baseUrl + `/art/${id}`;
  if (isSmallDevice) {
    video_url = video_url + "?type=v";
  } else {
    video_url = video_url + "?type=h";
  }
  const pdf_url = baseUrl + `/pdf/${id}`;

  return (
    data && (
      <Container>
        <h1 dangerouslySetInnerHTML={{ __html: data.text }}></h1>
        <div>
          <video
            controls
            width="100%"
            autoPlay={true}
            muted={true}
            loop={false}
          >
            <source src={video_url} />
            Your browser does not support the video tag.
          </video>
        </div>
        <a rel="noreferrer" target="_blank" href={pdf_url}>
          <h1>Certificate of authenticity</h1>
        </a>
        <a rel="noreferrer" target="_blank" href={data.reality}>
          <h1>Virtual Reality</h1>
        </a>
      </Container>
    )
  );
}

import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Youtube } from "../../components/Video";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(
  require.context("../../projects/22", false, /\.(png|jpe?g|svg|JPG)$/)
);

function Creatore() {
  return (
    <>
      <div className="projectBlock">
        <p>
          <b>
            "CREATORE" — A Short Film by Mariia Plekhova premiered within the
            framework of the 60th Venice Biennale
          </b>
        </p>
        <p>
          "CREATORE" is a short film entirely created with the use of artificial
          intelligence and directed by artist Mariia Plekhova. The film explores
          the interaction between humans and AI through the lens of creation,
          drawing a parallel between God creating humans and humans creating
          artificial intelligence. It emphasizes that we are the creators of a
          new intelligence. The film visually traces humanity’s journey from the
          dawn of life on Earth to the present day, where AI evolves from being
          merely a tool to becoming a partner in progress — assisting in
          creation, healing, education, and restoration of the world.
        </p>
        <p>
          The premiere of "CREATORE" took place within the framework of the 60th
          Venice Biennale during the event “Personal Structures | Public
          Screenings,” organized by the European Cultural Centre. The film won
          the Italia Media Art Festival as a laureate, marking its significant
          achievement.
        </p>
        <p>
          Through compelling visual storytelling, "CREATORE" highlights the
          harmony between technology and humanity, reflecting the notion that AI
          can be a force for good when used in the service of peace, as urged by
          Pope Francis.
        </p>
      </div>
      <div className="projectBlock">
        <div>
          <Youtube
            width="560"
            height="315"
            src="https://www.youtube.com/embed/DNxDEPyVbk4?si=G1aBNoRQWVrrviJV"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></Youtube>
        </div>
        <Carousel autoPlay={true} infiniteLoop={true} showIndicators={false}>
          {images.map((im) => (
            <img src={im} alt="photo2" key={im} />
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default Creatore;

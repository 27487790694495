import Img1 from './2023/2023_1.jpg'
import Img2 from './2023/2023_2.jpg'
import Img3 from './2023/2023_3.jpg'
import Img4 from './2023/2023_4.jpg'
import Img5 from './2023/2023_5.jpg'
import Img6 from './2023/2023_6.jpg'
import Img7 from './2023/2023_7.jpg'

import Img1s from './2023s/2023_1.jpg'
import Img2s from './2023s/2023_2.jpg'
import Img3s from './2023s/2023_3.jpg'
import Img4s from './2023s/2023_4.jpg'
import Img5s from './2023s/2023_5.jpg'
import Img6s from './2023s/2023_6.jpg'
import Img7s from './2023s/2023_7.jpg'


export const images2023 = [
    {
        src: Img1,
        src_s: Img1s,
        id: 1,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img2,
        src_s: Img2s,
        id: 2,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img3,
        src_s: Img3s,
        id: 3,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img4,
        src_s: Img4s,
        id: 4,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img5,
        src_s: Img5s,
        id: 5,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img6,
        src_s: Img6s,
        id: 6,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img7,
        src_s: Img7s,
        id: 7,
        caption: "foo",
        thumbnailCaption: "bar",
    },
]

import React from "react";
import getProjectPage from "./project_pages";
import { useParams } from "react-router-dom";
import styled from "styled-components";

export const PageContainer = styled.div`
  /* margin: 30px; */
  display: flex;
  text-align: justify;

  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }

  .projectBlock {
    margin: 15px;
    width: 50%;
    @media only screen and (max-width: 768px) {
      width: auto;
      text-align: initial;
    }
    a {
      font-weight: bold;
      color: black;
    }
  }
`;

function ProjectPage() {
  const props = useParams();
  return <PageContainer>{getProjectPage(props.project_name)}</PageContainer>;
}

export default ProjectPage;

import Img1 from './2020/2020_1.jpg'
import Img2 from './2020/2020_2.jpg'
import Img3 from './2020/2020_3.jpg'
import Img4 from './2020/2020_4.jpg'
import Img5 from './2020/2020_5.jpg'
import Img6 from './2020/2020_6.jpeg'
import Img7 from './2020/2020_7.jpg'
import Img8 from './2020/2020_8.jpg'
import Img9 from './2020/2020_9.jpg'
import Img10 from './2020/2020_10.jpg'
import Img11 from './2020/2020_11.jpg'
import Img12 from './2020/2020_12.jpg'
import Img13 from './2020/2020_13.jpg'
import Img14 from './2020/2020_14.jpg'
import Img15 from './2020/2020_15.jpg'
import Img16 from './2020/2020_16.jpg'
import Img17 from './2020/2020_17.jpg'
import Img18 from './2020/2020_18.jpg'
import Img19 from './2020/2020_19.jpg'
import Img20 from './2020/2020_20.jpg'
import Img21 from './2020/2020_21.jpg'
import Img22 from './2020/2020_22.jpg'
import Img23 from './2020/2020_23.jpg'
import Img24 from './2020/2020_24.jpg'
import Img25 from './2020/2020_25.jpg'
import Img26 from './2020/2020_26.jpg'
import Img27 from './2020/2020_27.jpg'
import Img28 from './2020/2020_28.jpg'
import Img29 from './2020/2020_29.jpg'

import Img1s from './2020s/2020_1.jpg'
import Img2s from './2020s/2020_2.jpg'
import Img3s from './2020s/2020_3.jpg'
import Img4s from './2020s/2020_4.jpg'
import Img5s from './2020s/2020_5.jpg'
import Img6s from './2020s/2020_6.jpeg'
import Img7s from './2020s/2020_7.jpg'
import Img8s from './2020s/2020_8.jpg'
import Img9s from './2020s/2020_9.jpg'
import Img10s from './2020s/2020_10.jpg'
import Img11s from './2020s/2020_11.jpg'
import Img12s from './2020s/2020_12.jpg'
import Img13s from './2020s/2020_13.jpg'
import Img14s from './2020s/2020_14.jpg'
import Img15s from './2020s/2020_15.jpg'
import Img16s from './2020s/2020_16.jpg'
import Img17s from './2020s/2020_17.jpg'
import Img18s from './2020s/2020_18.jpg'
import Img19s from './2020s/2020_19.jpg'
import Img20s from './2020s/2020_20.jpg'
import Img21s from './2020s/2020_21.jpg'
import Img22s from './2020s/2020_22.jpg'
import Img23s from './2020s/2020_23.jpg'
import Img24s from './2020s/2020_24.jpg'
import Img25s from './2020s/2020_25.jpg'
import Img26s from './2020s/2020_26.jpg'
import Img27s from './2020s/2020_27.jpg'
import Img28s from './2020s/2020_28.jpg'
import Img29s from './2020s/2020_29.jpg'


export const images2020 = [
    {
        src: Img1,
        src_s: Img1s,
        id: 28,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img2,
        src_s: Img2s,
        id: 29,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img3,
        src_s: Img3s,
        id: 30,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img4,
        src_s: Img4s,
        id: 31,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img5,
        src_s: Img5s,
        id: 32,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img6,
        src_s: Img6s,
        id: 33,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img7,
        src_s: Img7s,
        id: 34,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img8,
        src_s: Img8s,
        id: 35,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img9,
        src_s: Img9s,
        id: 36,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img10,
        src_s: Img10s,
        id: 37,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img11,
        src_s: Img11s,
        id: 38,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img12,
        src_s: Img12s,
        id: 39,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img13,
        src_s: Img13s,
        id: 40,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img14,
        src_s: Img14s,
        id: 41,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img15,
        src_s: Img15s,
        id: 42,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img16,
        src_s: Img16s,
        id: 43,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img17,
        src_s: Img17s,
        id: 44,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img18,
        src_s: Img18s,
        id: 45,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img19,
        src_s: Img19s,
        id: 46,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img20,
        src_s: Img20s,
        id: 47,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img21,
        src_s: Img21s,
        id: 48,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img22,
        src_s: Img22s,
        id: 49,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img23,
        src_s: Img23s,
        id: 50,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img24,
        src_s: Img24s,
        id: 51,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img25,
        src_s: Img25s,
        id: 52,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img26,
        src_s: Img26s,
        id: 53,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img27,
        src_s: Img27s,
        id: 54,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img28,
        src_s: Img28s,
        id: 55,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img29,
        src_s: Img29s,
        id: 56,
        caption: "foo",
        thumbnailCaption: "bar",
    }

]

import Img1 from './2019/2019_1.jpg'
import Img2 from './2019/2019_2.jpg'
import Img3 from './2019/2019_3.jpg'
import Img4 from './2019/2019_4.jpg'
import Img5 from './2019/2019_5.jpg'
import Img6 from './2019/2019_6.jpg'
import Img7 from './2019/2019_7.jpg'
import Img8 from './2019/2019_8.jpg'
import Img9 from './2019/2019_9.jpg'
import Img10 from './2019/2019_10.jpg'
import Img11 from './2019/2019_11.jpg'
import Img12 from './2019/2019_12.jpg'


import Img1s from './2019s/2019_1.jpg'
import Img2s from './2019s/2019_2.jpg'
import Img3s from './2019s/2019_3.jpg'
import Img4s from './2019s/2019_4.jpg'
import Img5s from './2019s/2019_5.jpg'
import Img6s from './2019s/2019_6.jpg'
import Img7s from './2019s/2019_7.jpg'
import Img8s from './2019s/2019_8.jpg'
import Img9s from './2019s/2019_9.jpg'
import Img10s from './2019s/2019_10.jpg'
import Img11s from './2019s/2019_11.jpg'
import Img12s from './2019s/2019_12.jpg'


export const images2019 = [
    {
        src: Img1,
        src_s: Img1s,
        id: 57,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img2,
        src_s: Img2s,
        id: 58,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img3,
        src_s: Img3s,
        id: 59,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img4,
        src_s: Img4s,
        id: 60,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img5,
        src_s: Img5s,
        id: 61,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img6,
        src_s: Img6s,
        id: 62,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img7,
        src_s: Img7s,
        id: 63,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img8,
        src_s: Img8s,
        id: 64,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img9,
        src_s: Img9s,
        id: 65,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img10,
        src_s: Img10s,
        id: 66,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img11,
        src_s: Img11s,
        id: 67,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img12,
        src_s: Img12s,
        id: 68,
        caption: "foo",
        thumbnailCaption: "bar",
    },
]

import React from "react";
import { Youtube } from "../../components/Video";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function YoutubePage() {
  return (
    <>
      <div className="projectBlock">
        <p>
          <b>
            Welcome to our{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://youtube.com/@artintelrome"
            >
              YouTube channel
            </a>{" "}
            "Art Intelligence"!
          </b>
        </p>
        <p>
          We are Mariia Plekhova, Ah Sasha, Anita Kutlinskaya - contemporary
          artists and passionate art lovers, living in Rome, Italy.
        </p>
        <p>
          We're here to share the wonderful world of art with you and invite you
          to join us on our walks through galleries, exhibitions, and museums
          together! From paintings to perfomances, classical to contemporary
          art, we'll dive into all forms of artistic expression, bringing you
          along for the journey. Explore the bustling streets of Rome, where
          every corner holds the potential for inspiration.
        </p>
        <p>Stay up-to-date about new exhibitions and on-going art events.</p>
        <p>
          Subscribe to "Art Intelligence" and join us on this artistic adventure
          for inspiration and beauty!
        </p>
      </div>
      <div className="projectBlock">
        <div>
          <Youtube
            width="560"
            height="315"
            src="https://www.youtube.com/embed/k2uchXO_jso"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></Youtube>
        </div>
        <div>
          <Youtube
            width="560"
            height="315"
            src="https://www.youtube.com/embed/xc2CYjaxM3M"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></Youtube>
        </div>
      </div>
    </>
  );
}

export default YoutubePage;

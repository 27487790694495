import Img1 from './2017/2017_1.png'
import Img2 from './2017/2017_2.png'
import Img3 from './2017/2017_3.png'
import Img4 from './2017/2017_4.png'
import Img5 from './2017/2017_5.png'
import Img6 from './2017/2017_6.png'
import Img7 from './2017/2017_7.png'
import Img8 from './2017/2017_8.png'
import Img9 from './2017/2017_9.png'
import Img10 from './2017/2017_10.png'
import Img11 from './2017/2017_11.png'
import Img12 from './2017/2017_12.png'
import Img13 from './2017/2017_13.png'

import Img1s from './2017s/2017_1.png'
import Img2s from './2017s/2017_2.png'
import Img3s from './2017s/2017_3.png'
import Img4s from './2017s/2017_4.png'
import Img5s from './2017s/2017_5.png'
import Img6s from './2017s/2017_6.png'
import Img7s from './2017s/2017_7.png'
import Img8s from './2017s/2017_8.png'
import Img9s from './2017s/2017_9.png'
import Img10s from './2017s/2017_10.png'
import Img11s from './2017s/2017_11.png'
import Img12s from './2017s/2017_12.png'
import Img13s from './2017s/2017_13.png'

export const images2017 = [
    {
        src: Img1,
        src_s: Img1s,
        id: 81,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img2,
        src_s: Img2s,
        id: 82,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img3,
        src_s: Img3s,
        id: 83,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img4,
        src_s: Img4s,
        id: 84,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img5,
        src_s: Img5s,
        id: 85,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img6,
        src_s: Img6s,
        id: 86,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img7,
        src_s: Img7s,
        id: 87,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img8,
        src_s: Img8s,
        id: 88,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img9,
        src_s: Img9s,
        id: 89,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img10,
        src_s: Img10s,
        id: 90,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img11,
        src_s: Img11s,
        id: 91,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img12,
        src_s: Img12s,
        id: 92,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img13,
        src_s: Img13s,
        id: 93,
        caption: "foo",
        thumbnailCaption: "bar",
    },
]

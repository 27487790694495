import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";
import "react-lazy-load-image-component/src/effects/blur.css";
import { NavLink as Link } from "react-router-dom";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  /* Small devices such as large phones (640px and up) */
  @media only screen and (max-width: 47em) {
    grid-template-columns: repeat(1, 1fr);
  }

  /* Medium devices such as tablets (768px and up) */
  @media only screen and (min-width: 48em) {
    grid-template-columns: repeat(2, 1fr);
  }

  /* Large devices such as laptops (1024px and up) */
  @media only screen and (min-width: 64em) {
    grid-template-columns: repeat(3, 1fr);
  }

  /* Largest devices such as desktops (1280px and up) */
  @media only screen and (min-width: 80em) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const ImageContainer = styled(Link)`
  margin: 30px;
  display: flex;
  flex-direction: column;
  text-align: center;
  /* font-family: Arial, Helvetica, sans-serif; */
  /* font-family: "Roboto Mono", monospace; */
  text-decoration: none;
  color: #808080;
`;

export const Image = styled(LazyLoadImage)`
  position: relative;
  z-index: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  width: 100%;
`;

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* font-size: 0.9vw; */
  font-size: 1rem;
  color: black;

  /* Small devices such as large phones (640px and up) */
  @media only screen and (max-width: 47em) {
    font-size: 0.7rem;
  }

  /* Medium devices such as tablets (768px and up) */
  @media only screen and (min-width: 48em) {
    font-size: 0.7rem;
  }

  /* Large devices such as laptops (1024px and up) */
  @media only screen and (min-width: 64em) {
    font-size: 0.8rem;
  }

  /* Largest devices such as desktops (1280px and up) */
  @media only screen and (min-width: 80em) {
    font-size: 1rem;
  }
`;

export const SpinnerContainer = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
`
import Img1 from './2018/2018_1.jpg'
import Img2 from './2018/2018_2.jpg'
import Img3 from './2018/2018_3.jpg'
import Img4 from './2018/2018_4.jpg'
import Img5 from './2018/2018_5.jpg'
import Img6 from './2018/2018_6.jpg'
import Img7 from './2018/2018_7.jpg'
import Img8 from './2018/2018_8.jpg'
import Img9 from './2018/2018_9.jpg'
import Img10 from './2018/2018_10.jpg'
import Img11 from './2018/2018_11.jpg'
import Img12 from './2018/2018_12.jpg'

import Img1s from './2018s/2018_1.jpg'
import Img2s from './2018s/2018_2.jpg'
import Img3s from './2018s/2018_3.jpg'
import Img4s from './2018s/2018_4.jpg'
import Img5s from './2018s/2018_5.jpg'
import Img6s from './2018s/2018_6.jpg'
import Img7s from './2018s/2018_7.jpg'
import Img8s from './2018s/2018_8.jpg'
import Img9s from './2018s/2018_9.jpg'
import Img10s from './2018s/2018_10.jpg'
import Img11s from './2018s/2018_11.jpg'
import Img12s from './2018s/2018_12.jpg'

export const images2018 = [
    {
        src: Img1,
        src_s: Img1s,
        id: 69,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img2,
        src_s: Img2s,
        id: 70,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img3,
        src_s: Img3s,
        id: 71,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img4,
        src_s: Img4s,
        id: 72,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img5,
        src_s: Img5s,
        id: 73,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img6,
        src_s: Img6s,
        id: 74,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img7,
        src_s: Img7s,
        id: 75,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img8,
        src_s: Img8s,
        id: 76,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img9,
        src_s: Img9s,
        id: 77,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img10,
        src_s: Img10s,
        id: 78,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img11,
        src_s: Img11s,
        id: 79,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img12,
        src_s: Img12s,
        id: 80,
        caption: "foo",
        thumbnailCaption: "bar",
    },
]

import React from "react";
import { Youtube } from "../../components/Video";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(
  require.context("../../projects/3", false, /\.(png|jpe?g|svg)$/)
);

function OnTheWall() {
  return (
    <>
      <div className="projectBlock">
        <p>
          <span>Solo exhibition "Island. Things Are Not as They Seem"</span>
        </p>
        <p>
          <span>Cinema "Зарница", Nizhny Novgorod, Russia; sept 2019</span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            Mariia Plekhova's solo exhibition "Island. Things Are Not as They
            Seem" explores the culture of Sicily through her paintings, created
            using the technique she began working with in Italy. This is already
            the third time Mariia has showcased her works in an exhibition.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            In her exhibition, Mariia unveils the layers of Sicilian culture
            through her paintings, relying solely on her internal sensations and
            ideas of what she wants to convey. The layers overlap each other,
            but as a result, the viewer can feel and understand the "invisible"
            part of the painting by delving a little deeper. A similar process
            occurs when encountering a different culture.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            When living in a new country, one begins to realize why things
            happen the way they do and how everything that initially seems
            strange and unusual has its own explanation. Therefore, through her
            artworks, Mariia demonstrates that things are not as they seem.
            Regarding culture, by focusing on the key elements of Sicilian life
            in her paintings, the artist reveals their true essence.
          </span>
        </p>
      </div>
      <div className="projectBlock">
        <div>
          <Youtube
            width="560"
            height="315"
            src="https://www.youtube.com/embed/Y1A__ZRkcak"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></Youtube>
        </div>
        <div>
          <Youtube
            width="560"
            height="315"
            src="https://www.youtube.com/embed/xGNQE2YtjUY"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          >
          </Youtube>
        </div>
        <Carousel autoPlay={true} infiniteLoop={true} showIndicators={false}>
          {images.map((im) => (
            <img src={im} alt="photo2" key={im} />
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default OnTheWall;

import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(
  require.context("../../projects/25", false, /\.(png|jpe?g|svg|JPG)$/)
);

function CongressEmotion() {
  return (
    <>
      <div className="projectBlock">
        <p>
          <b>
            Keynote Speaker at the 2nd World Congress on Facial Expression of
            Emotion, speech - "The Impact of Visual Art on Emotional
            Intelligence Development"
          </b>
        </p>
        <p>
          In October 2024, artist Mariia Plekhova was a keynote speaker at the
          2nd World Congress on Facial Expression of Emotion in Porto, Portugal,
          presenting on "The Impact of Visual Art on Emotional Intelligence
          Development." Mariia Plekhova shared the stage with global thought
          leaders and renowned scholars. Her talk delved into the transformative
          role of visual art in enhancing emotional intelligence (EI),
          emphasizing its growing importance across a range of fields—from
          healthcare and social work to leadership, entrepreneurship, and
          technical professions like engineering and programming.
        </p>
        <p>
          The conference featured distinguished speakers, including professors
          from some of the world's top universities, such as the University of
          Southern California, Harvard University, New York University, the
          University of Oxford, Aarhus University, and Kyoto University. Among
          them was Daniel Goleman, one of the most influential figures in
          bringing the concept of emotional intelligence into the mainstream.
          Mariia Plekhova also cited his work during her presentation, enriching
          her discussion on the significance of EI in today's world.
        </p>
      </div>
      <div className="projectBlock">
        <Carousel autoPlay={true} infiniteLoop={true} showIndicators={false}>
          {images.map((im) => (
            <img src={im} alt="photo2" key={im} />
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default CongressEmotion;

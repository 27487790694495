import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Youtube } from "../../components/Video";

function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(
  require.context("../../projects/23", false, /\.(png|jpe?g|svg|JPG)$/)
);

function Filo() {
  return (
    <>
      <div className="projectBlock">
        <p>
          <b>Presentation of a revolutionary Art Project "FILO" </b>
          <b>
            by Mariia Plekhova and Giovanni Stella Grande in collaboration with
            Nicolaquinto
          </b>
        </p>
        <p>
          On November 23, Nicolaquinto Italia hosted one of the most anticipated
          events of the Rome Art Week, unveiling the exclusive FILO exhibition—a
          groundbreaking project that merges art with objects of everyday life.
          Created by artist Mariia Plekhova and project director Giovanni Stella
          Grande, FILO is a unique technique that integrates artwork into items
          like sofas, transforming them into true masterpieces. The opening
          night drew over 150 guests, who were captivated by Mariia Plekhova’s
          symbolic performance of cutting a canvas, emphasizing the importance
          of transformation and innovation. FILO not only redefines the concept
          of art but also reshapes how we live it—an ode to the symbiosis
          between form and function, between art and life.
        </p>
        <p>Press & Publications</p>
        <p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.ansa.it/canale_lifestyle/notizie/design_giardino/2024/11/04/arte-e-design-crossover-tra-quadri-e-oggetti-di-arredo_b45ff77c-c0f1-43a4-838e-911bcda89aff.html"
          >
            ANSA.IT (IT)
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.diarioinduscom.com.br/Edicao_Online/1716/13_11_2024?fbclid=PAZXh0bgNhZW0CMTEAAaaPf8riLI0tnIFH0yAoqigS-NFZUBrbdo3DgCEdWh7xIj7HAPVn1ayMA_U_aem_wOt6Ba8lcZdNJlkCVlZzEA"
          >
            Diário Indústria& Comércio (BR)
          </a>
        </p>
      </div>
      <div className="projectBlock">
        <div>
          <Youtube
            width="560"
            height="315"
            src="https://www.youtube.com/embed/88mxpm_J8VY?si=1Fsy7dDJQJLQbwUc"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></Youtube>
        </div>
        <Carousel autoPlay={true} infiniteLoop={true} showIndicators={false}>
          {images.map((im) => (
            <img src={im} alt="photo2" key={im} />
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default Filo;

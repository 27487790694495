import React from "react";
import { Image } from "../components/Image/Image";
import { Youtube } from "../components/Video";
import Img from "../common/1_about.jpg";
import styled from "styled-components";
import CV from "./cv.pdf";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px;
  div {
    text-align: justify;
    padding: 0 100px;
    @media screen and (max-width: 1200px) {
      padding: 0;
    }
    @media screen and (max-width: 768px) {
      /* text-align: inherit; */
    }
  }
  img {
    margin-bottom: 15px;
    width: 100%;
  }
  .videos {
    margin-top: 30px;
    display: flex;
    gap: 30px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    iframe {
      @media screen and (max-width: 768px) {
        height: 200px;
      }
    }
  }
`;

const About = () => {
  const [ready, setReady] = React.useState(false);

  return (
    <Container>
      <div>
        <Image
          src={Img}
          effect="blur"
          alt="MARIIA"
          onLoad={() => setReady(true)}
        />
      </div>

      {ready && (
        <>
          <div>
            Mariia Plekhova b.2002, is an artist based in Italy and Cyprus,&nbsp;
            <a
              rel="noreferrer"
              target="_blank"
              href={CV}
            >
              CV
            </a>
          </div>
          <div className="videos">
            <Youtube
              src="https://www.youtube.com/embed/Aj0HIDfqk38?si=D2yCYUqy1v32bzcD"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></Youtube>
            <Youtube
              src="https://www.youtube.com/embed/70fjMi6QAyw"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></Youtube>
          </div>
          <div className="videos">
            <Youtube
              src="https://www.youtube.com/embed/7UbjcIJxBtQ"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></Youtube>
            <Youtube
              src="https://www.youtube.com/embed/unIlxiPjHq8"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></Youtube>
          </div>
          <div>
            <h4>ARTIST STATEMENT AND BIOGRAPHY </h4>
            <p>
              <span>
                Mariia Plekhova was born in 2002 in Nizhny Novgorod, Russia.
                Currently residing in Europe (Italy &amp; Cyprus){" "}
              </span>
            </p>
            <p>
              <span>
                Mariia has held exhibitions in Russia and Italy and has worked
                on social projects. Her creative path began in Nizhny Novgorod,
                and she later studied sculpture and painting at an art lyceum in
                Italy (Sicily). Currently, the artist continues her creative
                development in Rome.{" "}
              </span>
            </p>
            <p>
              <span>
                Mariia&rsquo;s paintings are a message of well-being, joy and
                good emotions, characterized by a personal, abstract style that
                has met with considerable success in large cities of Russia and
                in Italy. Now the artist creates hybrid paintings and explores
                the possibilities of virtual reality{" "}
              </span>
            </p>
            <p>
              <span>
                Mariia is convinced that art is a profound concept and has a
                social function. It is important both for the life of each one
                of us and of society as a whole. Involvement of representatives
                of various segments of the population in artistic activity can
                improve the context in which we live.{" "}
              </span>
            </p>
            <p>
              <span>
                The language of art is understandable and accessible to all,
                thanks to which it is possible solve urgent problems and convey
                important information to a large number of people.
                <br /> Art unites us and helps prevent conflicts, harmonizing
                the situation in society.{" "}
              </span>
            </p>
            <p>
              <span>
                Thi is why Mariia carried out the various social projects: the
                "Clean sheet" project involved children with disabilities, in
                artistic activities, the project "There is a choice!" which has
                convey the importance and awareness of our food choices. And she
                also took part in the development of a design for an application
                for children with disabilities in partnership with the
                University of London.{" "}
              </span>
            </p>
            <p>
              <span>
                For four years, Mariia has been organizing creative workshops
                for children and adults. Her motto is "Creativity in the
                present, success in the future!". Already more than 500 people
                took part in the workshops of the artist.{" "}
              </span>
            </p>
            <p>
              <span>
                Mariia is a guest of numerous television and radio programs in
                Russia and Italy. She has collaborated with many scientific
                institutions and companies, such as Exness, Netcracker, the
                All-Russian Research Institute of Phytopathology, State
                Technical University named after Alekseev, Lobachevsky State
                University, Centro culturale Aurelio (Rome).{" "}
              </span>
            </p>
            <h4>ARTISTIC STYLE </h4>
            <p>
              <span>
                The style of the artist is built around two fundamental
                concepts: color and movement. They represent the perfect
                symbiosis between energy and harmony and each work has its own
                distinctive characteristics including the dynamics and the
                sensation of an infinite flow of vital energy. Mariia tries to
                represent the world through the prism of her feelings and
                emotions. Viewers perceive the emotions such as joy and
                happiness. Impulsiveness, speed, calm are transformed into
                luminous reflections and take shape.{" "}
              </span>
            </p>
            <p>
              <span>
                Mariia also creates hybrid paintings at the intersection of two
                realities using VR technologies.{" "}
              </span>
            </p>
            <p>
              <span>
                The artist's hybrid paintings extend beyond the tangible and
                continue into virtual reality. MariIa believes that the task of
                creativity is to make what seems impossible become real.{" "}
              </span>
            </p>
            <h4>SELECTED EXHIBITIONS </h4>
            <p>
              <span>
                APR 2023 - JUN 2023
                <br /> PARTICIPATION TO THE ART FESTIVAL OF INTARNATIONAL
                ARTISTS "VERNISSAGE" Club 79, Rome, Italy{" "}
              </span>
            </p>
            <p>
              <span>
                SEPT 2022 - NOV 2022
                <br /> PARTICIPATION TO THE COLLECTIVE EXHIBITION "UN ANNO DI
                NOA"
                <br /> Noa Gallery (Nuova officina delle arti), Rome, Italy{" "}
              </span>
            </p>
            <p>
              <span>
                SEPT 2022
                <br /> PERSONAL EXHIBITION &ldquo;ORIGINS" Polytechnic
                Institute, Nizhny Novgorod, Russia{" "}
              </span>
            </p>
            <p>
              <span>
                AUG 2022
                <br /> PARTICIPATION TO THE LARGEST OPEN- AIR GALLERY IN SICILY
                "NOTARTE 2022" Noto, Italy{" "}
              </span>
            </p>
            <p>
              <span>
                JUN 2022 - JUL 2022
                <br /> PARTICIPATION TO THE INTERNATIONAL COLLECTIVE EXHIBITION
                "PREMIO CITTÀ DI MONTECOSARO"
                <br /> Teatro delle logge, Montecosaro, Italia{" "}
              </span>
            </p>
            <p>
              <span>
                OCT 2021
                <br /> PERSONAL EXHIBITION
                <br /> "
              </span>
              <span>ВЫБОР ЕСТЬ</span>
              <span>!" ("THERE IS A CHOICE!") Cinema "</span>
              <span>Орленок</span>
              <span>" Nizhny Novgorod, Russia </span>
            </p>
            <p>
              <span>
                MAY 2021 - SEPT 2021
                <br /> PERSONAL EXHIBITION "CLEAN SHEET"
                <br /> Nizhny Novgorod Cultural and Scientific Information
                Center, Nizhny Novgorod, Russia{" "}
              </span>
            </p>
            <p>
              <span>
                APR 2021
                <br /> PERSONAL EXHIBITION "TRUTH"
                <br /> Cinema "
              </span>
              <span>Зарница</span>
              <span>", Nizhny Novgorod, Russia </span>
            </p>
            <p>
              <span>
                OCT 2019 - FEB 2020
                <br /> PERMANENT PERSONAL EXHIBITION "ISLAND" Office of
                Netcracher company, Nizhny Novgorod, Russia{" "}
              </span>
            </p>
            <p>
              <span>
                PERSONAL EXHIBITION "ISLAND"
                <br /> Cinema "
              </span>
              <span>Зарница</span>
              <span>", Nizhny Novgorod, Russia </span>
            </p>
            <p>
              <span>
                GEN 2019
                <br /> PERSONAL EXHIBITION "ASTRAZIONI SONORE" ("SOUND
                ABSTRACTIONS")
                <br /> Palazzo delle Arti, ex Convitto Ragusa, Noto, Italy{" "}
              </span>
            </p>
            <p>
              <span>
                SEPT 2018
                <br /> PERSONAL EXHIBITION "A PAINTING FLOATS
                <br /> ON THE WALL"
                <br /> Museum of the city of Nizhny Novgorod, Nizhny Novgorod,
                Russia{" "}
              </span>
            </p>
            <p>
              <span>
                DEC 2017 - GEN 2018
                <br /> PARTICIPATION TO THE COLLECTIVE EXHIBITION "ARTISTIC
                <br /> PROJECT: FUFACHEV'S LABORATORY" cinema "
              </span>
              <span>Орленок</span>
              <span>", Nizhny Novgorod, Russia </span>
            </p>
            <p>
              <span>
                SEPT 2017
                <br /> PARTICIPATION TO THE COLLECTIVE NATIONAL EXHIBITION "ART
                RUSSIA" Nizhny Novgorod Fair, Nizhny Novgorod, Russia{" "}
              </span>
            </p>
            <h4>COLLABORATIONS</h4>
            <p>
              <span>
                APR-MAY 2023
                <br /> COLLABORATION WITH VERNISSAGE ORGANISATION, ITALY, ROME
                implementation of VR live painting at the art festival of
                international artists in Rome exploring new visual language{" "}
              </span>
            </p>
            <p>
              <span>ОСТ </span>
              <span>
                2022
                <br /> COLLABORATION WITH EXNESS GROUP, AN INTERNATIONAL
                AWARD-WINNING MULTI-ASSET BROKER
                <br /> implementation of creative workshops for company
                employees{" "}
              </span>
            </p>
            <p>
              <span>
                SEP-OCT 2022
                <br /> COLLABORATION WITH A NOA (NUOVA OFFICINA DELLE ARTI)
                GALLERY IN ROME, ITALY{" "}
              </span>
            </p>
            <p>
              <span>
                SEPT 2022
                <br /> COLLABORATION WITH STATE TECHNICAL UNIVERSITY NAMED AFTER
                ALEKSEEV NIZHNY NOVGOROD, RUSSIA
                <br /> joint organization of exhibition{" "}
              </span>
            </p>
            <p>
              <span>
                AUG 2022
                <br /> REPRESENTATIVE OF THE ROMAN CULTURAL CENTER "CENTRO
                CULTURALE AURELIO" OF THE CITYWIDE FESTIVAL "ESTATE ROMANA
                <br /> 2022" SUPPORTED BY THE GOVERNMENT OF ROME
                <br /> implementation of creative workshops with open accesss
                for citizens of Rome{" "}
              </span>
            </p>
            <p>
              <span>
                MAR 2021- MAY 2021
                <br /> COLLABORATION WITH SCHOOL FOR DEATH CHILDREN
                <br /> NIZHNY NOVGOROD, RUSSIA
                <br /> organization of creative workshops for students as part
                of a social project{" "}
              </span>
            </p>
            <p>
              <span>
                SEPT 2021
                <br /> COLLABORATION WITH THE "ALL-RUSSIAN RESEARCH
                PHYTOPATHOLOGY INSTITUTE"
                <br /> MOSCOW, RUSSIA
                <br /> organization of exhibitions and events, and public
                relations{" "}
              </span>
            </p>
            <p>
              <span>
                MAY 2021
                <br /> COLLABORATION WITH THE &ldquo;NIZHNY NOVGOROD CULTURAL
                AND SCIENTIFIC INFORMATION CENTER&rdquo;
                <br /> NIZHNY NOVGOROD, RUSSIA
                <br /> joint organization of exhibition{" "}
              </span>
            </p>
            <p>
              <span>
                MAR 2020
                <br /> COLLABORATION WITH NETCRACKER COMPANY NIZHNY NOVGOROD,
                RUSSIA
                <br /> permanent painting exhibition{" "}
              </span>
            </p>
            <h4>PROFESSIONAL EXPERIENCE</h4>
            <p>
              <span>
                AUG 2022
                <br /> PROMOTION OF SICILIAN CULTURE THROUGH
                <br /> CREATIVE WORKSHOPS AS PART OF THE OPEN GALLERY "NOTARTE"
                implementation of creative workshops for tuorists{" "}
              </span>
            </p>
            <p>
              <span>
                JUL 2022
                <br /> DEVELOPING AN APP FOR CHILDREN WITH DISABILITIES WITH A
                TEAM FROM THE UNIVERSITY OF LONDON
                <br /> responsible for the graphic design of the application{" "}
              </span>
            </p>
            <p>
              <span>
                MAR 2022 - JUL 2022
                <br /> TEACHING AT THE SCHOOL OF ADDITIONAL DEVELOPMENT OF ROME
                "CENTRO CULTURALE AURELIO" Subject: Painting{" "}
              </span>
            </p>
            <p>
              <span>
                FEB 2022 - OCT 2022
                <br /> TEACHING AT THE BILINGUAL SCHOOL OF ROME &laquo;
              </span>
              <span>ЗНАНИЕ</span>
              <span>&raquo; </span>
            </p>
            <p>
              <span>
                MAY 2021 - SEPT 2021
                <br /> DESIGN PROJECT REALISATION FOR THE RESTAURANT "SHADE",
                DZERZHINSK, RUSSIA{" "}
              </span>
            </p>
            <p>
              <span>
                JUL 2019 - PRESENT
                <br /> INTRODUCTION TO THE MARKET
                <br /> THE DRAWING SCHOOL &ldquo;CLEAN SHEET&rdquo;{" "}
              </span>
            </p>
            <p>
              <span>FOR CHILDREN AND ADULTS </span>
            </p>
            <p>
              <span>
                JUN - JUL 2019
                <br /> APPRENTICESHIP IN RESTORATION OF THE CHURCH OF MADONNA
                DEL CARMINE IN NOTO
                <br /> Artistic high school Matteo Raeli, Noto, Italy{" "}
              </span>
            </p>
            <p>
              <span>
                MAY 2019
                <br /> PARTICIPATION TO THE REALISATION OF FESTIVAL OF FLOWERS
                "INFIORATA" IN NOTO, SICILY, ITALY{" "}
              </span>
            </p>
            <h4>NOTABLE RESULTS IN COMPETITIONS </h4>
            <p>
              <span>
                AUG 2022
                <br /> EUROPEAN LEVEL ARTIST PRIZE &ldquo;PREMIO CITTÀ DI
                MONTECOSARO&rdquo; MONTECOSARO, ITALY{" "}
              </span>
            </p>
            <p>
              <span>
                JUN 2021
                <br /> PARTICIPATION IN THE NATIONAL SOCIALLY SIGNIFICANT
                PROJECT "AMBASSADORS OF THE FUTURE FOR THE REGIONS" WITH THE
                SUPPORT OF THE "RIGHT TO LIFE" SOCIAL FUND, MOSCOW, RUSSIA
                <br /> participation in the filming of a social video intended
                to motivate teenagers to adopt a healthy and active lifestyle{" "}
              </span>
            </p>
            <p>
              <span>
                MAY 2021
                <br /> WINNER OF THE STRATEGIC INITIATIVE COMPETITION
                &ldquo;PERSONNEL OF THE FUTURE FOR THE REGIONS&rdquo; NIZHNY
                NOVGOROD, RUSSIA
                <br /> launch and realisation of a social projects the "Clean
                sheet" that involved children with disabilities, in artistic
                activities{" "}
              </span>
            </p>
            <p>
              <span>
                OCT 2019
                <br /> WINNER OF THE COMPETITION OF INNOVATIVE BUSINESS IDEAS
                "INNOBUSINESS" IN LOBACHEVSKY UNIVERSITY, NIZHNY NOVGOROD,
                RUSSIA{" "}
              </span>
            </p>
            <p>
              <span>
                SEPT 2019
                <br /> PARTICIPANT IN THE EXHIBITION-COMPETITION OF YOUTH
                INNOVATIVE PROJECTS "TERRITORY INTERNATIONAL INNOVATIONS" WITHIN
                THE FRAMEWORK OF THE NATIONAL FESTIVAL OF YOUTH INNOVATIONS
                "INNOFEST", NIZHNY NOVGOROD, RUSSIA{" "}
              </span>
            </p>
            <p>
              <span>
                FEB 2019
                <br /> WINNER OF A PHOTO CONTEST "HOW I SEE ITALY" IN THE
                FRAMEWORK OF INTERCULTURAL EXCHANGE CULTURAL EXCHANGE PROGRAM
                INTERCULTURA, IVREA, ITALY{" "}
              </span>
            </p>

            <p>
              <span>
                WINNER OF THE ALL-RUSSIAN YOUTH SHORT FILM FESTIVAL "SUNRISE",
                KALININGRAD, RUSSIA
                <br /> work as a director on the creation of a short film{" "}
              </span>
              <h4>SCIENTIFIC ACTIVITY </h4>
              <span>
                2023
                <br /> Scientific publication "Hybrid art as a modern
                communicative practice youth"
                <br /> in the collection of proceedings of the youth conference
                "Communicative practices of modern youth: prospects and
                challenges&rdquo; (accepted for publication){" "}
              </span>
            </p>
            <p>
              <span>
                2023
                <br /> Participation in the international Youth conference
                "Communicative practices of modern youth: perspectives and
                challenges"
                <br /> Report &ldquo;Hybrid art as modern communicative practice
                of youth&rdquo;{" "}
              </span>
            </p>
            <p>
              <span>
                2022
                <br /> Scientific publication &ldquo;Import substitution as a
                factor in ensuring economic security of the country&rdquo;
                <br /> Plekhova M.D., Ludushkina E.N., Plekhova Y.O., Aldabaeva
                M.S. / Economy and entrepreneurship, No. 7(144). pp. 56-60.{" "}
              </span>
            </p>
            <p>
              <span>
                2021
                <br /> Scientific publication "The role of international
                exchange programs in establishing intercultural communications
                on the example of the activities of the Interculture Foundation
                AFS Russia"
                <br /> in the Proceedings of the VI International Conference
                &ldquo;Harmonization inter-ethnic relations in a global
                society&rdquo;, pp. 71-74.{" "}
              </span>
            </p>
            <p>
              <span>
                2020
                <br /> Participation in the National online scientific
                conference of students &ldquo;Youth. The science. Culture&rdquo;
                <br /> Report &ldquo;Legal regulation of social relations
                emerging in connection with the development and application of
                systems with artificial intelligence&rdquo;{" "}
              </span>
            </p>
            <p>
              <span>
                2020
                <br /> Participation in the VI International Scientific
                Conference "Harmonization of interethnic relations in conditions
                of a global society&rdquo;
                <br /> Report &ldquo;The role of programs international exchange
                in establishing intercultural communications on the example of
                activities of the Interculture Foundation of AFS&rdquo;{" "}
              </span>
            </p>
          </div>
        </>
      )}
    </Container>
  );
};

export default About;

import styled from "styled-components";

export const Youtube = styled.iframe`
  width: 100%;
  /* height: 100%; */
  /* max-width: 460px; */
  height: 350px;

  @media only screen and (max-width: 768px) {
    /* width: 400px;
    height: 260px; */
  }
`;

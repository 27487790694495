import React from "react";
import { Youtube } from "../../components/Video";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(
  require.context("../../projects/6", false, /\.(png|jpe?g|svg|JPG)$/)
);

function ArtSchool() {
  return (
    <>
      <div className="projectBlock">
        <p>
          <b>
            Creating an online and offline art school and organizing creative
            workshops
          </b>
        </p>
        <p>
          Mariia Plekhova has created her own art school called "Blank Sheet,"
          which exists in both online and offline formats.
        </p>
        <p>
          For four years, the artist has been conducting creative workshops for
          children and adults, with over 500 people from different countries
          having already participated. Among her clients are companies such as
          the Federal State Budgetary Scientific Institution "All-Russian
          Research Institute of Phytopathology" (Moscow), Gazprom, Exness
          (Cyprus), Centro culturale Aurelio (Italy), Netcracker Technology, and
          the Alekseev Polytechnic Institute.
        </p>
        <p>
          The artist's motto is "Creativity in the present leads to success in
          the future!" Maria teaches not how to draw, but how to embrace
          freedom. New ideas emerge when the fear of making mistakes dissipates.
          Her workshops are aimed at exploring new solutions and overcoming the
          fear of making errors.
        </p>
        <p>
          Maria's work helps people unleash their creativity in various areas:
          fostering creative relationships within families, leading in a
          different way, devising new business models, and making unconventional
          decisions. People respond positively to this approach, as they start
          buying paints after a few classes and begin drawing independently,
          which can be considered a significant outcome. Maria's students may
          not become professional artists, but drawing helps them express and
          cope with their emotions while also providing them with creative
          problem-solving skills in other aspects of life.
        </p>
        <p>
          It is essential to note that the benefits of drawing are not dependent
          on the student's artistic skill level or age. The spontaneous drawing
          method works for any student, not just those who can draw well.
        </p>
        <p>
          The state of creative flow is a natural rhythm for individuals that
          automatically brings them into balance. This balanced state helps
          people cope with stress, overcome energy deficiencies, gain a better
          understanding of themselves, and find new and unconventional solutions
          to challenges. Results from surveys conducted among participants in
          Maria's workshops indicate the following positive effects of engaging
          in artistic activities: innovative problem-solving in professional and
          personal life, a sense of unity with others, heightened
          self-awareness, and a desire to continue artistic pursuits
          independently.
        </p>
      </div>
      <div className="projectBlock">
        <div>
          <Youtube
            width="560"
            height="315"
            src="https://www.youtube.com/embed/aRQwivPI6Xw"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></Youtube>
        </div>
        <div>
          <Youtube
            width="560"
            height="315"
            src="https://www.youtube.com/embed/KGUzfzj08KM"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></Youtube>
        </div>
        <Carousel autoPlay={true} infiniteLoop={true} showIndicators={false}>
          {images.map((im) => (
            <img src={im} alt="photo2" key={im} />
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default ArtSchool;

import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(
  require.context("../../projects/19", false, /\.(png|jpe?g|svg|JPG)$/)
);

function PortugalVR() {
  return (
    <>
      <div className="projectBlock">
        <p>
          <b>
            VR painting workshop and exhibition "On the Edge of Reality - Time
            to Unite Realities" in collaboration with Portuguese University
            Fernando Pessoa
          </b>
        </p>
        <p>
          The project aims to engage students from the Fernando Pessoa
          University in creative activities and introduce them to virtual
          reality technologies through a VR painting workshop and an exhibition
          of the created artworks.
        </p>
        <p>
          The project also includes an online voting component to select
          artworks for the exhibition. Participation is open to individuals or
          groups. Waiting for your requests.
        </p>
      </div>
      <div className="projectBlock">
        <Carousel autoPlay={true} infiniteLoop={true} showIndicators={false}>
          {images.map((im) => (
            <img src={im} alt="photo2" key={im} />
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default PortugalVR;

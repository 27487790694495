import { images2024 } from './2024';
import { images2023 } from './2023';
import { images2022 } from './2022';
import { images2021 } from './2021';
import { images2020 } from './2020';
import { images2019 } from './2019';
import { images2018 } from './2018';
import { images2017 } from './2017';


const getImgsByYear = (year) => {
    switch (year) {
        case '2024':
            return images2024
        case '2023':
            return images2023
        case '2022':
            return images2022
        case '2021':
            return images2021
        case '2020':
            return images2020
        case '2019':
            return images2019
        case '2018':
            return images2018
        case '2017':
            return images2017
        default:
            return images2024
    }
}

export default getImgsByYear;
import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(
  require.context("../../projects/9", false, /\.(png|jpe?g|svg|JPG)$/)
);

function PremioMontecosaro() {
  return (
    <>
      <div className="projectBlock">
        <p>
          <b>
            Participation in the international exhibition «PREMIO CITTA' DI
            MONTECOSARO» Montecosaro, Marche, Italy; jul 2022
          </b>
        </p>
        <p>
          Mariia Plekhova partecipated in an international exhibition "PREMIO
          CITTA' DI MONTECOSARO," and also received an art award of the European
          level.
        </p>
        <p>
          The exhibition, titled "PREMIO CITTA' DI MONTECOSARO," was inaugurated
          on Sunday, July 10, 2022, at the Teatro delle Logge. The event was
          organized by the Center of Art and Culture in collaboration with the
          Municipality of Montecosaro, curated by Marianna Jurcova and Mauro
          Giampieri, and enjoyed the moral patronage of the Marche Region, the
          Province of Macerata, the Municipality of Montecosaro, the Slovak
          Embassy in Rome, the Slovak Institute in Rome, and the Legislative
          Assembly of the Marches.
        </p>
        <p>
          Artist Maria Plekhova received the European-level art award, the "City
          of Montecosaro Prize." The awards ceremony took place at the Teatro
          delle Logge in Montecosaro and was presented by Mayor Reano Malaisi.
        </p>
        <p>
          The award ceremony was attended by 52 artists selected from Italy and
          various countries, including Slovakia, Austria, Russia, Romania,
          Finland, Argentina, Switzerland, the Czech Republic, Germany, Ukraine,
          Bulgaria, Greece, among others.
        </p>
      </div>
      <div className="projectBlock">
        <Carousel autoPlay={true} infiniteLoop={true} showIndicators={false}>
          {images.map((im) => (
            <img src={im} alt="photo2" key={im} />
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default PremioMontecosaro;

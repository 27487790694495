import React from "react";
import { Youtube } from "../../components/Video";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(
  require.context("../../projects/18", false, /\.(png|jpe?g|svg|JPG)$/)
);

function TEDX() {
  return (
    <>
      <div className="projectBlock">
        <p>
          <b>
            Speaker at the TEDxLUMSA conference (technology, entertainment,
            design) awards. Speech - 'Think outside the box - Creativity in the
            present, success in the future’
          </b>
        </p>
        <p>
          Speaker at the TEDxLUMSA conference (technology, entertainment,
          design) awards. The mission of the conference is 'ideas worth
          spreading'. And the participants are outstanding representatives of
          the world of science, art, politics, business, culture. How can
          creative activities help maintain competitiveness in the labor market
          in the digital future? How not to get lost in the world of machines
          and artificial intelligence? The answers to all these questions in
          speech of Mariia Plekhova 'Think outside the box - Creativity in the
          present, success in the future’.
        </p>
      </div>
      <div className="projectBlock">
        <div>
          <Youtube
            width="560"
            height="315"
            src="https://www.youtube.com/embed/09BCKaGIVAk?si=nQZTUs6zUGdpvbIK"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </div>
        <Carousel autoPlay={true} infiniteLoop={true} showIndicators={false}>
          {images.map((im) => (
            <img src={im} alt="photo2" key={im} />
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default TEDX;

import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Youtube } from "../../components/Video";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(
  require.context("../../projects/12", false, /\.(png|jpe?g|svg|JPG)$/)
);

function CleanSheet() {
  return (
    <>
      <div className="projectBlock">
        <p>
          <b>
            Mariia Plekhova's social project "Clean sheet" which aims to involve
            children with disabilities in creative activities Nizhny Novgorod,
            Russia; apr-jun 2021
          </b>
        </p>
        <p>
          Mariia Plekhova initiated the social project "Clean sheet," which aims
          to involve children with disabilities in creative activities. The
          artist conducted a series of creative workshops for the students of
          the State Educational Institution "Boarding School for Deaf Children,"
          teaching them her drawing techniques and approach. The final stage of
          the project was a city exhibition showcasing the participants'
          artworks.
        </p>
        <p>
          As a result of the workshops, the children experienced a sense of
          freedom, joy in creating something beautiful, emotional release, and a
          feeling of the significance of their activities. The exhibition and
          media coverage drew public attention to the creative development of
          children with disabilities and became the beginning of collaboration
          between corrective schools and artists in Nizhny Novgorod.
        </p>
      </div>
      <div className="projectBlock">
        <div>
          <Youtube
            width="560"
            height="315"
            src="https://www.youtube.com/embed/gH8p5zSnFWI"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></Youtube>
        </div>
        <div>
          <Youtube
            width="560"
            height="315"
            src="https://www.youtube.com/embed/kjQEo7V-0ME"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></Youtube>
        </div>
        <div>
          <Youtube
            width="560"
            height="315"
            src="https://www.youtube.com/embed/KIMwBUHrvQA"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></Youtube>
        </div>
        <div>
          <Youtube
            width="560"
            height="315"
            src="https://www.youtube.com/embed/KPhQHOENfTQ"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></Youtube>
        </div>
        <Carousel autoPlay={true} infiniteLoop={true} showIndicators={false}>
          {images.map((im) => (
            <img src={im} alt="photo2" key={im} />
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default CleanSheet;

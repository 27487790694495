import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(
  require.context("../../projects/7", false, /\.(png|jpe?g|svg|JPG)$/)
);

function Exness() {
  return (
    <>
      <div className="projectBlock">
        <p>
          <b>
            Collaboration with Exness Group, an international award-winning
            multi-asset broker
          </b>
        </p>
        <p>implementation of creative workshops for company employees</p>
      </div>
      <div className="projectBlock">
        <Carousel autoPlay={true} infiniteLoop={true} showIndicators={false}>
          {images.map((im) => (
            <img src={im} alt="photo2" key={im} />
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default Exness;

import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Youtube } from "../../components/Video";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(
  require.context("../../projects/10", false, /\.(png|jpe?g|svg|JPG)$/)
);

function Vernissage() {
  return (
    <>
      <div className="projectBlock">
        <p>
          <b>
            Participation in an international art festival “Vernissage” in Rome
            Rome, Italy; may 2023
          </b>
        </p>
        <p>
          Mariia Plekhova implemented a live painting in Virtual Reality and
          presented her hybrid artworks.
        </p>
        <p>
          Future has arrived. Technology allows us to create art that goes
          beyond the boundaries of reality. New horizons of creativity open up.
          The technology of virtual reality enables artists to create immersive
          and visually captivating artworks that allow viewers to dive into
          alternative words and experience unique emotional and artistic
          impressions. Viewer becomes a participant in the artwork immersing
          themselves in the narrative and interacting with piece.
        </p>
        <p>
          Welcome to new era in art! My debut in VR live painting at the
          @vernissagerome - a unique art festival of international artists in
          Rome exploring new visual language
        </p>
      </div>
      <div className="projectBlock">
        <div>
          <Youtube
            width="560"
            height="315"
            src="https://www.youtube.com/embed/86WdS8clfEI"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></Youtube>
        </div>
        <Carousel autoPlay={true} infiniteLoop={true} showIndicators={false}>
          {images.map((im) => (
            <img src={im} alt="photo2" key={im} />
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default Vernissage;

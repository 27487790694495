import Title1 from "./1/1.jpg";
import Title2 from "./2/1.jpg";
import Title3 from "./3/1.jpg";
import Title4 from "./4/1.jpg";
import Title5 from "./5/1.jpg";
import Title6 from "./6/1.jpg";
import Title7 from "./7/1.jpg";
import Title8 from "./8/1.jpg";
import Title9 from "./9/1.jpg";
import Title10 from "./10/1.jpg";
import Title11 from "./11/1.jpg";
import Title12 from "./12/1.jpg";
import Title13 from "./13/1.jpg";
import Title14 from "./14/1.jpg";
import Title15 from "./15/1.jpg";
import Title16 from "./16/1.jpg";
import Title17 from "./17/1.jpg";
import Title18 from "./18/1.jpg";
import Title19 from "./19/1.jpg";
import Title20 from "./20/1.jpg";
import Title21 from "./21/1.JPG";
import Title22 from "./22/1.jpg";
import Title23 from "./23/1.jpg";
import Title24 from "./24/1.jpg";
import Title25 from "./25/1.jpg";

const projects = [
  {
    src_s: Title23,
    id: 2022,
    captionEl: (
      <>
        <p></p>
        <span>
          Presentation of a revolutionary Art Project "FILO" by Mariia Plekhova
          and Giovanni Stella Grande in collaboration with Nicolaquinto
        </span>
      </>
    ),
    thumbnailCaption: "bar",
    to: "/projects/filo",
  },
  {
    src_s: Title25,
    id: 2024,
    captionEl: (
      <>
        <p></p>
        <span>
          Keynote Speaker at the 2nd World Congress on Facial Expression of
          Emotion, speech - "The Impact of Visual Art on Emotional Intelligence
          Development"
        </span>
      </>
    ),
    thumbnailCaption: "bar",
    to: "/projects/congress_emotion",
  },
  {
    src_s: Title24,
    id: 2023,
    captionEl: (
      <>
        <p></p>
        <span>
          Participation in Maker Faire Rome – The European Edition 2024: Italia
          Media Art Festival with Virtual Reality Painting workshop "At the Edge
          of Reality"
        </span>
      </>
    ),
    thumbnailCaption: "bar",
    to: "/projects/edge_of_reality",
  },
  {
    src_s: Title22,
    id: 2021,
    captionEl: (
      <>
        <p></p>
        <span>
          "CREATORE" — A Short Film by Mariia Plekhova premiered within the
          framework of the 60th Venice Biennale
        </span>
      </>
    ),
    thumbnailCaption: "bar",
    to: "/projects/creatore",
  },
  {
    src_s: Title20,
    id: 2019,
    captionEl: (
      <>
        <p></p>
        <span>MARIIA PLEKHOVA & NICOLAQUINTO</span>
        <span>project FILO (Fusione di Interni e Lavoro d'Opera)</span>
      </>
    ),
    thumbnailCaption: "bar",
    to: "/projects/nicolaquinto",
  },
  {
    src_s: Title18,
    id: 2017,
    captionEl: (
      <>
        <p></p>
        <span>
          Speaker at the TEDxLUMSA conference (technology, entertainment,
          design) awards.
        </span>
        <span>
          Speech - 'Think outside the box - Creativity in the present,
        </span>
        <span>success in the future’</span>
      </>
    ),
    thumbnailCaption: "bar",
    to: "/projects/tedx",
  },
  {
    src_s: Title21,
    id: 2020,
    captionEl: (
      <>
        <p></p>
        <span>
          Participation in ROME ART WEEK and presentation of the FILO project,
          merging art and design, in collaboration with NICOLAQUINTO Italia
        </span>
      </>
    ),
    thumbnailCaption: "bar",
    to: "/projects/artweek",
  },
  {
    src_s: Title19,
    id: 2018,
    captionEl: (
      <>
        <p></p>
        <span>
          VR painting workshop and exhibition "On the Edge of Reality - Time to
          Unite Realities"
        </span>
        <span>in collaboration with Portuguese University Fernando Pessoa</span>
      </>
    ),
    thumbnailCaption: "bar",
    to: "/projects/portugal_vr",
  },
  {
    src_s: Title1,
    id: 2000,
    caption: `Exhibition-performance “ASTRAZIONI SONORE”
        by Mariia Plekhova and Mario Rapisardi. Noto, Italy; gen 2019`,
    captionEl: (
      <>
        <p></p>
        <span>Exhibition-performance “ASTRAZIONI SONORE” </span>
        <span>by Mariia Plekhova and Mario Rapisardi</span>
        <span>Noto, Italy; gen 2019</span>
      </>
    ),
    thumbnailCaption: "astrazioni_sonore",
    to: "/projects/astrazioni_sonore",
  },
  {
    src_s: Title16,
    id: 2015,
    captionEl: (
      <>
        <p></p>
        <span>Participation to the collective exhibition "VENTUNO"</span>
        <span>Noa Gallery (Nuova officina delle arti),</span>
        <span>Rome, Italy; jul 2023 - sept 2023</span>
      </>
    ),
    thumbnailCaption: "bar",
    to: "/projects/ventuno",
  },
  {
    src_s: Title10,
    id: 2009,
    captionEl: (
      <>
        <p></p>
        <span>
          Participation in an international art festival “Vernissage” in Rome
        </span>
        <span>Rome, Italy; may 2023</span>
      </>
    ),
    thumbnailCaption: "bar",
    to: "/projects/vernissage",
  },
  {
    src_s: Title15,
    id: 2014,
    captionEl: (
      <>
        <p></p>
        <span>Participation in the XVIII edition of the NotArte Festival</span>
        <span>Noto, Sicily, Italy; aug 2022</span>
      </>
    ),
    thumbnailCaption: "bar",
    to: "/projects/notarte_festival",
  },
  {
    src_s: Title9,
    id: 2008,
    captionEl: (
      <>
        <p></p>
        <span>
          Participation in the international exhibition «PREMIO CITTA' DI
          MONTECOSARO»
        </span>
        <span>Montecosaro, Marche, Italy; jul 2022</span>
      </>
    ),
    thumbnailCaption: "bar",
    to: "/projects/premio_montecosaro",
  },
  {
    src_s: Title7,
    id: 2006,
    captionEl: (
      <>
        <p></p>
        <span>Collaboration with Exness Group,</span>
        <span>an international award-winning multi-asset broker</span>
      </>
    ),
    thumbnailCaption: "bar",
    to: "/projects/exness",
  },
  {
    src_s: Title11,
    id: 2010,
    captionEl: (
      <>
        <p></p>
        <span>Participation to the collective exhibition</span>
        <span>"UN ANNO DI NOA"</span>
        <span>Noa Gallery (Nuova officina delle arti),</span>
        <span>Rome, Italy; sept 2022 - nov 2022</span>
      </>
    ),
    thumbnailCaption: "bar",
    to: "/projects/noa_gallery",
  },
  {
    src_s: Title14,
    id: 2013,
    captionEl: (
      <>
        <p></p>
        <span>
          Participation in the Italian government project «ROMAN SUMMER 2022»
        </span>
        <span>Italy, Rome; aug 2022</span>
      </>
    ),
    thumbnailCaption: "bar",
    to: "/projects/roman_summer",
  },
  {
    src_s: Title12,
    id: 2011,
    captionEl: (
      <>
        <p></p>
        <span>Mariia Plekhova's social project "Clean sheet"</span>
        <span>which aims to involve children</span>
        <span>with disabilities in creative activities</span>
        <span>Nizhny Novgorod, Russia; apr-jun 2021</span>
      </>
    ),
    thumbnailCaption: "bar",
    to: "/projects/clean_sheet",
  },
  {
    src_s: Title2,
    id: 2001,
    captionEl: (
      <>
        <p />
        <span>Solo exhibition "A painting floats on the wall"</span>
        <span>Museum of the city of Nizhny Novgorod,</span>
        <span>Nizhny Novgorod, Russia; sept 2018</span>
      </>
    ),
    thumbnailCaption: "bar",
    to: "/projects/on_the_wall",
  },
  {
    src_s: Title3,
    id: 2002,
    captionEl: (
      <>
        <p></p>
        <span>Solo exhibition</span>
        <span>"Island. Things Are Not as They Seem"</span>
        <span>Cinema &quot;Зарница&quot;, Nizhny Novgorod, Russia;</span>
        <span>sept 2019</span>
      </>
    ),
    thumbnailCaption: "bar",
    to: "/projects/isola",
  },
  {
    src_s: Title4,
    id: 2003,
    captionEl: (
      <>
        <p></p>
        <span>Solo exhibition "Choice Exists"</span>
        <span>by Mariia Plekhova and Oleg Kumagin,</span>
        <span>with the All-Russian Scientific Research</span>
        <span>Institute of Phytopathology.</span>
        <span>
          Cinema &quot;Орленок&quot; Nizhny Novgorod, Russia; oct 2021
        </span>
      </>
    ),
    thumbnailCaption: "bar",
    to: "/projects/choice_exists",
  },
  {
    src_s: Title5,
    id: 2004,
    captionEl: (
      <>
        <p></p>
        <span>Exhibition "Origins"</span>
        <span>by Maria Plekhova and Oleg Kumagin in collaboration</span>
        <span>
          with Nizhny Novgorod State Technical University (R.E. Alekseev)
        </span>
        <span>
          Nizhny Novgorod State Technical University, Nizhny Novgorod, Russia;
          sept 2022
        </span>
      </>
    ),
    thumbnailCaption: "bar",
    to: "/projects/origins",
  },
  {
    src_s: Title8,
    id: 2007,
    captionEl: (
      <>
        <p></p>
        <span>The All-Russian project "Future Personnel for the Regions"</span>
      </>
    ),
    thumbnailCaption: "bar",
    to: "/projects/future_of_regions",
  },
  {
    src_s: Title13,
    id: 2012,
    captionEl: (
      <>
        <p></p>
        <span>Participation in the national socially significant project</span>
        <span>"AMBASSADORS OF THE FUTURE FOR THE REGIONS"</span>
        <span>with the support of the "Right to life"</span>
        <span>social fund, Moscow, Russia</span>
      </>
    ),
    thumbnailCaption: "bar",
    to: "/projects/right_to_life",
  },
  {
    src_s: Title6,
    id: 2005,
    captionEl: (
      <>
        <p></p>
        <span>Creating an online and offline art school</span>
        <span>and organizing creative workshop</span>
      </>
    ),
    thumbnailCaption: "bar",
    to: "/projects/art_school",
  },
  {
    src_s: Title17,
    id: 2016,
    captionEl: (
      <>
        <p></p>
        <span>Creating a YouTube channel “Art intelligence”</span>
      </>
    ),
    thumbnailCaption: "bar",
    to: "/projects/youtube",
  },
];

export default projects;

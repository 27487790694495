import React from "react";
import { Youtube } from "../../components/Video";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(
  require.context("../../projects/15", false, /\.(png|jpe?g|svg|JPG)$/)
);

function NotarteFestival() {
  return (
    <>
      <div className="projectBlock">
        <p>
          <b>
            Participation in the XVIII edition of the NotArte Festival Noto,
            Sicily, Italy; aug 2022
          </b>
        </p>
        <p>
          Mariia Plekhova participated in the XVIII edition of the NotArte
          Festival, recognized as the largest open gallery in Sicily. It serves
          as a meeting place and cultural exchange hub, firmly established in
          the artistic landscape of the island, and renews its appointment under
          the stars in the splendid Baroque town.
        </p>
        <p>
          The ribbon-cutting ceremony on Sunday, August 7, 2022, was led by
          Mayor Corrado Figura, who once again confirmed the artistic direction
          of Cristina Cataneo and Vincenzo Medica. They aimed to present this
          edition as an Itinerarium Artis in Noto.
        </p>
      </div>
      <div className="projectBlock">
        <div>
          <Youtube
            width="560"
            height="315"
            src="https://www.youtube.com/embed/pY8L9fS0aO0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></Youtube>
        </div>
        <Carousel autoPlay={true} infiniteLoop={true} showIndicators={false}>
          {images.map((im) => (
            <img src={im} alt="photo2" key={im} />
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default NotarteFestival;

import React from "react";
import { Link } from "react-router-dom";
import './navbar.css'
import styled from 'styled-components';

export const SidebarData = [
    {
        title: "About",
        path: "/about",
        cName: "nav-text",
    },
    {
        title: "Paintings",
        path: "/paintings",
        cName: "nav-text",
    },
    {
        title: "Projects",
        path: "/projects",
        cName: "nav-text",
    },
    {
        title: "Contact",
        path: "/contact",
        cName: "nav-text"
    },
];

const SideMenu = styled.nav`
  background-color: #fff;
  height: 100vh;
  display: flex;
  opacity: 0.9;
  z-index: 200;
  right: -0;
  /* left: 60%; */
  /* width: 40%; */
  position: fixed;
`;

const InvDiv = styled.div`
  opacity:    0;
  background: #000;
  width:      100%;
  height:     100%;
  z-index:    1;
  top:        0;
  left:       0;
  position:   fixed;
`

const SideMenuItems = styled.ul`
  padding: 0;
`;

export default function Sidebar({ closeBar }) {
    // eslint-disable-next-line
    React.useEffect(() => () => closeBar(), [])
    return (
        <>
            <SideMenu onClick={closeBar} >
                <SideMenuItems>
                    {SidebarData.map((item, index) => {
                        return (
                            <li key={index} className={item.cName}>
                                <Link to={item.path}>
                                    {item.icon}
                                    <span>{item.title}</span>
                                </Link>
                            </li>
                        );
                    })}
                </SideMenuItems>
            </SideMenu>
            <InvDiv onClick={closeBar} />
        </>
    );
}

import React from "react";
import {
  Image,
  ImageContainer,
  Grid,
  TextContainer,
  SpinnerContainer,
} from "./Image";
import { trackWindowScroll } from "react-lazy-load-image-component";
import { BeatLoader } from "react-spinners";

const GridElement = ({ src, captionEl, to, ...imgProps }) => {
  const [loaded, setLoaded] = React.useState(false);
  return (
    <ImageContainer to={to}>
      <Image
        src={src}
        effect="blur"
        onLoad={() => {
          setLoaded(true);
        }}
        {...imgProps}
      />
      {!loaded && (
        <SpinnerContainer>
          <BeatLoader color="gray" size={10} />
        </SpinnerContainer>
      )}
      {/* <span style={{width: width}}>{e.caption}</span> */}
      {!!captionEl && loaded && <TextContainer>{captionEl}</TextContainer>}
    </ImageContainer>
  );
};

const _ImagesGrid = ({ elements, ...props }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [elements]);

  return (
    <Grid>
      {elements.map((e) => (
        <GridElement
          key={e.id}
          src={e.src_s}
          captionEl={e.captionEl}
          to={e.to}
          {...props}
        />
      ))}
    </Grid>
  );
};

export const ImagesGrid = trackWindowScroll(_ImagesGrid);

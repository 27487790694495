import Img1 from './2022/2022_1.jpg'
import Img2 from './2022/2022_2.jpg'
import Img3 from './2022/2022_3.jpg'
import Img4 from './2022/2022_4.jpg'
import Img5 from './2022/2022_5.jpg'
import Img6 from './2022/2022_6.jpg'
import Img7 from './2022/2022_7.jpg'
import Img8 from './2022/2022_8.jpg'
import Img9 from './2022/2022_9.jpg'
import Img10 from './2022/2022_10.jpg'
import Img11 from './2022/2022_11.jpg'
import Img12 from './2022/2022_12.jpg'
import Img13 from './2022/2022_13.jpg'
import Img14 from './2022/2022_14.jpg'

import Img1s from './2022s/2022_1.jpg'
import Img2s from './2022s/2022_2.jpg'
import Img3s from './2022s/2022_3.jpg'
import Img4s from './2022s/2022_4.jpg'
import Img5s from './2022s/2022_5.jpg'
import Img6s from './2022s/2022_6.jpg'
import Img7s from './2022s/2022_7.jpg'
import Img8s from './2022s/2022_8.jpg'
import Img9s from './2022s/2022_9.jpg'
import Img10s from './2022s/2022_10.jpg'
import Img11s from './2022s/2022_11.jpg'
import Img12s from './2022s/2022_12.jpg'
import Img13s from './2022s/2022_13.jpg'
import Img14s from './2022s/2022_14.jpg'


export const images2022 = [
    {
        src: Img1,
        src_s: Img1s,
        id: 8,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img2,
        src_s: Img2s,
        id: 9,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img3,
        src_s: Img3s,
        id: 10,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img4,
        src_s: Img4s,
        id: 11,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img5,
        src_s: Img5s,
        id: 12,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img6,
        src_s: Img6s,
        id: 13,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img7,
        src_s: Img7s,
        id: 14,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img8,
        src_s: Img8s,
        id: 15,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img9,
        src_s: Img9s,
        id: 16,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img10,
        src_s: Img10s,
        id: 17,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img11,
        src_s: Img11s,
        id: 18,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img12,
        src_s: Img12s,
        id: 19,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img13,
        src_s: Img13s,
        id: 20,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img14,
        src_s: Img14s,
        id: 21,
        caption: "foo",
        thumbnailCaption: "bar",
    },
]

import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(
  require.context("../../projects/16", false, /\.(png|jpe?g|svg|JPG)$/)
);

function Ventuno() {
  return (
    <>
      <div className="projectBlock">
        <p>
          <b>
            Participation to the collective exhibition "VENTUNO" Noa Gallery
            (Nuova officina delle arti), Rome, Italy; jul 2023 - sept 2023
          </b>
        </p>
      </div>
      <div className="projectBlock">
        <Carousel autoPlay={true} infiniteLoop={true} showIndicators={false}>
          {images.map((im) => (
            <img src={im} alt="photo2" key={im} />
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default Ventuno;

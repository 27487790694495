import React from "react";
import { ImagesGrid } from "../components/Image";
import { useParams } from "react-router-dom";
import getImgsByYear from "../paintings";

function Paintings() {
  const props = useParams();
  const images = getImgsByYear(props.year);
  // return <ImagesGrid elements={images} />;
  return <ImagesGrid elements={images} width="100%" height="100%" />;
}

export default Paintings;

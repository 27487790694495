import Img1 from './2024/1.jpg'
import Img2 from './2024/2.jpg'
import Img3 from './2024/3.jpg'
import Img4 from './2024/4.jpg'
import Img5 from './2024/5.jpg'

import Img1s from './2024s/1.jpg'
import Img2s from './2024s/2.jpg'
import Img3s from './2024s/3.jpg'
import Img4s from './2024s/4.jpg'
import Img5s from './2024s/5.jpg'


export const images2024 = [
    {
        src: Img1,
        src_s: Img1s,
        id: 1,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img2,
        src_s: Img2s,
        id: 2,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img3,
        src_s: Img3s,
        id: 3,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img4,
        src_s: Img4s,
        id: 4,
        caption: "foo",
        thumbnailCaption: "bar",
    },
    {
        src: Img5,
        src_s: Img5s,
        id: 5,
        caption: "foo",
        thumbnailCaption: "bar",
    },
]

import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(
  require.context("../../projects/14", false, /\.(png|jpe?g|svg|JPG)$/)
);

function RomanSummer() {
  return (
    <>
      <div className="projectBlock">
        <p>
          <b>
            Participation in the Italian government project «ROMAN SUMMER 2022»
            Italy, Rome; aug 2022
          </b>
        </p>
        <p>
          Mariia Plekhova organized the creative laboratory "The Colors of
          Summer" as part of the "ROMAN SUMMER 2022" festival, which is
          supported by the municipality of Rome and created in collaboration
          with the Aurelio Cultural Center.
        </p>
        <p>
          The main objective of the "ROMAN SUMMER 2022" project is to combat
          urban decay and crime by fostering opportunities for interaction
          between different artistic forms and the multicultural social fabric
          of a peripheral area that typically lacks artistic programming, unlike
          the historic center of Rome. The project will be developed in squares,
          meeting places, parks, and elderly centers in this area, aiming to
          engage a target audience that is usually disconnected from artistic
          events. It will provide laboratory activities and stimulate new
          experiences. The festival's presence will contribute to improving the
          perception of urban security, enhancing the livability of spaces that
          are often affected by decay and crime, and promoting decorum. The use
          of art as a tool for social cohesion will bring together citizens,
          institutions, entities, and associations operating in the area.
        </p>
      </div>
      <div className="projectBlock">
        <Carousel autoPlay={true} infiniteLoop={true} showIndicators={false}>
          {images.map((im) => (
            <img src={im} alt="photo2" key={im} />
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default RomanSummer;

import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Youtube } from "../../components/Video";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(
  require.context("../../projects/8", false, /\.(png|jpe?g|svg|JPG)$/)
);

function FutureOfRegions() {
  return (
    <>
      <div className="projectBlock">
        <p>
          <b>
            Participation in the All-Russian project "Future Personnel for the
            Regions"
          </b>
        </p>
        <p>
          Presentation of the projects as part of the All-Russian strategic
          initiative "Future Personnel for the Regions" of the 2020-2021 season
          took place. The event was organized by the Ministry of Education,
          Science, and Youth Policy of the Nizhny Novgorod Region and the Nizhny
          Novgorod Scientific and Information Center.
        </p>
        <p>
          The social project "Clean cheet" by artist Mariia Plekhova, aimed at
          involving children with disabilities in creative activities, took
          first place and received a special nomination "Recognition of
          Colleagues".
        </p>
      </div>
      <div className="projectBlock">
        <div>
          <Youtube
            width="560"
            height="315"
            src="https://www.youtube.com/embed/D4lgwQ9sUkY?si=LZX3SJpLdCxxRF6i"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </div>
        <div>
          <Youtube
            width="560"
            height="315"
            src="https://www.youtube.com/embed/t-HN7GALFis?si=Z2P9NrfWKGGqVLaM"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </div>
        <div>
          <Youtube
            width="560"
            height="315"
            src="https://www.youtube.com/embed/emr3n0HKCk8?si=hLgf_9bPWxutgsnQ"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </div>
        <Carousel autoPlay={true} infiniteLoop={true} showIndicators={false}>
          {images.map((im) => (
            <img src={im} alt="photo2" key={im} />
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default FutureOfRegions;

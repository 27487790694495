import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Youtube } from "../../components/Video";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(
  require.context("../../projects/20", false, /\.(png|jpe?g|svg|JPG)$/)
);

function Nicolaquinto() {
  return (
    <>
      <div className="projectBlock">
        <p>
          <b>MARIIA PLEKHOVA & NICOLAQUINTO</b>
        </p>
        <b>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.filosart.com"
          >
            www.filosart.com
          </a>
        </b>
        <p>
          FILO is an art project created by the artist Mariia Plekhova together
          with the company Nicolaquinto Italia. The goal of the FILO team is to
          democratize art. The activity is aimed at creating an accessible
          environment for interaction with art. This becomes possible thanks to
          the technique of combining a traditional artistic canvas with an
          everyday object - a sofa, through mechanical interaction and mutual
          exchange of parts. Thanks to the FILO technique, an everyday object
          transforms its function and meaning and becomes an element of art.
        </p>
        <p>
          The Nicolaquinto showroom in the center of Rome will present a
          collection of sofas in symbiosis with the paintings of the artist
          Mariia Plekhova. In addition, each work has a continuation in virtual
          reality. Thanks to the technology of virtual reality glasses, guests
          will have the opportunity to immerse themselves inside each painting.
        </p>
        <p>
          The technique of symbiosis between a painting and a sofa is aimed at
          making art a part of a person's everyday life. Constant interaction
          with objects created with this technique allows the person to
          experience art on a different level, making it an integral part of
          their reality. Therefore, life, surrounded by such works, becomes a
          work of art itself.
        </p>
      </div>
      <div className="projectBlock">
        <div>
          <Youtube
            width="560"
            height="315"
            src="https://www.youtube.com/embed/-s6H74T2GA0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></Youtube>
        </div>
        <Carousel autoPlay={true} infiniteLoop={true} showIndicators={false}>
          {images.map((im) => (
            <img src={im} alt="photo2" key={im} />
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default Nicolaquinto;
